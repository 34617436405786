<template>
  <WeContainer card="" v-if="ready">
    <div>
      <Navigation
        v-on:save-form="submitForm"
        v-bind:submit="submit"
        v-bind:errors="errors"
      />
    </div>
    <div class="tab-content">
      <div class="tab-pane fade show active">
        <div class="row align-items-stretch mb-xl-3">
          <div class="col-12 mb-3 col-xl mb-xl-0">
            <div class="card h-100">
              <div class="card-body">
                <!-- Code -->
                <WeInput
                  label="Kupon Kodu"
                  v-model="couponCode"
                  v-bind:required="true"
                  v-bind:error="$v.couponCode.$error"
                />
                <!-- Code -->
                <!-- <div v-if="$route.params.id" class="row">
                  <div class="col-12 col-lg-6">
                    <WeInput
                      label="Oluşturan Kişi"
                      v-model="couponCreatedBy"
                      v-bind:disabled="true"
                    />
                  </div>
                  <div class="col-12 col-lg-6">
                    <div class="row">
                      <div class="col">
                        <label for="start-date" class="custom-label"
                          >Oluşturma Tarihi</label
                        >
                      </div>
                    </div>
                    <WeDatePicker
                      id="start-date"
                      label="Oluşturma Tarihi"
                      v-bind:format="'DD.MM.YYYY HH:mm'"
                      v-bind:output-format="'YYYY-MM-DD HH:mm'"
                      v-bind:locale="'tr'"
                      v-bind:no-label="true"
                      v-bind:no-button-now="true"
                      v-bind:no-clear-button="false"
                      v-bind:disabled="true"
                      v-model="couponCreatedAt"
                    />
                  </div>
                </div> -->
                <!-- Sale -->
                <div class="row">
                  <div class="col-12 col-lg-6">
                    <div class="form-group">
                      <label for="" class="custom-label">İndirim Türü</label>
                      <select class="custom-select" v-model="discountType">
                        <option
                          v-for="type in shared.discountTypes.list"
                          v-bind:key="type.id"
                          v-bind:value="type.id"
                        >
                          {{ type.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <WeInput
                      label="İndirim Miktarı"
                      v-model="discountAmount"
                      v-bind:required="true"
                      v-bind:error="$v.discountAmount.$error"
                      v-bind:filter-number="true"
                    />
                  </div>
                </div>
                <!-- Sale -->
                <!-- Start & End Date -->
                <div class="row mb-3">
                  <!-- Start Date -->
                  <div class="col-12 mb-3 col-lg mb-lg-0">
                    <div class="row">
                      <div class="col">
                        <label for="start-date" class="custom-label"
                          >Başlangıç Tarih/Saat</label
                        >
                      </div>
                    </div>
                    <WeDatePicker
                      id="start-date"
                      label="Başlangıç Tarih/Saat"
                      v-bind:format="'DD.MM.YYYY HH:mm'"
                      v-bind:output-format="'YYYY-MM-DD HH:mm'"
                      v-bind:locale="'tr'"
                      v-bind:no-label="true"
                      v-bind:no-button-now="true"
                      v-bind:no-clear-button="false"
                      v-model="data.startDate"
                    />
                  </div>
                  <!-- Start Date -->

                  <!-- End Date -->
                  <div class="col">
                    <div class="row">
                      <div class="col">
                        <label for="start-date" class="custom-label"
                          >Bitiş Tarih/Saat</label
                        >
                      </div>
                    </div>
                    <WeDatePicker
                      id="end-date"
                      label="Bitiş Tarih/Saat"
                      v-bind:format="'DD.MM.YYYY HH:mm'"
                      v-bind:output-format="'YYYY-MM-DD HH:mm'"
                      v-bind:locale="'tr'"
                      v-bind:no-label="true"
                      v-bind:no-button-now="true"
                      v-bind:no-clear-button="false"
                      v-bind:min-date="data.startDate"
                      v-model="data.dueDate"
                    />
                  </div>
                  <!-- End Date -->
                </div>
                <!-- ./Start & End Date -->

                <!-- Limit -->
                <WeLimitInput v-model="data.limit" />
                <!-- Limit -->

                <div class="row">
                  <div class="col-12 col-lg">
                    <!-- Cart Price -->
                    <WePriceInput
                      label="Minimum Sepet Tutarı"
                      tooltip="Kupon kodunun kullanılabilmesi için gereken minimum sepet tutarı"
                      tooltip-position="top"
                      v-model="data.cart_price"
                      ref="cartPrice"
                    />
                    <!-- Cart Price -->
                  </div>
                  <div class="col-12 col-lg">
                    <!-- Description -->
                    <WeInput
                      label="Açıklama"
                      v-model="couponName"
                      v-bind:required="true"
                      v-bind:error="$v.couponName.$error"
                      tooltip="Açıklama alanı sadece yönetim panelinde gözükecektir"
                      tooltip-position="top"
                    />
                    <!-- Description -->
                  </div>
                </div>

                <div class="row">
                  <!-- Status -->
                  <div class="col">
                    <label class="custom-label">Kupon Durumu</label>
                    <WeSwitch
                      v-model="data.is_active"
                      v-bind:show-status="true"
                    />
                  </div>
                  <!-- Status -->
                  <!-- Only Member -->
                  <div class="col">
                    <label class="custom-label"
                      >Sadece Üyeler Kullanabilir</label
                    >
                    <WeSwitch
                      v-model="data.only_member"
                      v-bind:show-status="true"
                    />
                  </div>
                  <!-- Only Member -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </WeContainer>
  <WeLoading v-else />
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import { getValue } from "vue-currency-input";
const Navigation = () => import("./views/Navigation/Index");

export default {
  name: "Detail",
  data() {
    return {
      limitTypes: [],
      data: {
        name: null,
        code: null,
        is_active: true,
        only_member: true,
        created_by: null,
        created_at: null,
        cart_price: helper.setPrice(0),
        discount: {
          amount: 0,
          type: null,
        },
        startDate: null,
        dueDate: null,
        limit: {
          type: "-1",
          type_id: 0,
          amount: 0,
          currency: -1,
        },
      },
      submit: false,
      ready: false,
      errors: [],
    };
  },
  components: {
    Navigation,
  },
  validations: {
    couponName: {
      required,
    },
    couponCode: {
      required,
    },
    discountAmount: {
      required,
    },
    // data: {
    //   limit: {
    //     type: {
    //       required,
    //     },
    //   },
    // },
  },
  methods: {
    ...mapActions("coupon", ["create", "update", "getById"]),
    ...mapActions("shared", [
      "getDiscountTypes",
      "getLimitTypes",
      "getCurrencyList",
    ]),
    ...mapMutations("shared", ["setMessage", "clearMessage"]),
    getCouponById() {
      if (this.$route.params.id) {
        this.getById({
          id: this.$route.params.id,
          onSuccess: (result) => {
            this.data = result;
            this.setLimitType();
          },
          onFinish: () => {
            this.ready = true;
          },
        });
      } else {
        this.ready = true;
      }
    },
    validateForm() {
      // this.setLimitTypeByIndex();
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.$swal({
          title: "Hata",
          text: "Lütfen zorunlu alanları doldurun",
          icon: "error",
        });

        return false;
      } else {
        return true;
      }
    },
    submitForm() {
      if (!this.validateForm()) {
        return;
      }

      this.submit = true;

      if (this.$route.params.id) {
        this.updateCoupon();
      } else {
        this.addCoupon();
      }
    },
    addCoupon() {
      this.create({
        form: this.data,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.$swal({
              title: "İşlem Başarılı",
              text: "Kupon listesine dönebilir veya bu sayfada kalabilirsiniz.",
              icon: "success",
              showCancelButton: true,
              cancelButtonText: "Detaya Git",
              confirmButtonText: "Liste Sayfasına Dön",
            }).then((confirm) => {
              if (confirm.isConfirmed) {
                this.redirectToList();
              } else {
                this.$router.push(`detail/${result.data.id}`);
              }
            });
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    updateCoupon() {
      this.update({
        id: this.$route.params.id,
        form: this.data,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.$swal({
              title: "İşlem Başarılı",
              text: "Özellikler listesine dönebilir veya bu sayfada kalabilirsiniz.",
              icon: "success",
              showCancelButton: true,
              cancelButtonText: "Sayfada Kal",
              confirmButtonText: "Liste Sayfasına Dön",
            }).then((confirm) => {
              if (confirm.isConfirmed) {
                this.redirectToList();
              } else {
                window.location.reload();
              }
            });
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    redirectToList() {
      this.$router.push("/coupons");
    },
    showAlert(result, callback) {
      this.setMessage(result);
      this.$swal(this.shared.message).then((confirm) => {
        if (callback && confirm.isConfirmed) callback();
      });
      this.clearMessage();
    },
    // setLimitTypeByIndex() {
    //   const index = this.data.limit.type;
    //   if (this.data.limit.type !== "") {
    //     this.data.limit.type_id = this.shared.limitTypes.list[index].id;
    //   }
    // },
    setLimitType() {
      const id = this.data.limit.type_id;
      if (id) {
        const limitTypes = this.shared.limitTypes.list;
        const index = helper.arrayFind(limitTypes, "id", id);

        this.data.limit.type = index;
      }
    },
  },
  computed: {
    ...mapState(["coupon", "shared", "session"]),
    getTitle() {
      return this.$route.params.id ? "KUPON DÜZENLE" : "KUPON EKLE";
    },
    couponName: {
      get() {
        return this.data.name;
      },
      set(value) {
        this.data.name = value;
      },
    },
    couponCreatedBy: {
      get() {
        return this.data.created_by;
      },
      set(value) {
        this.data.created_by = value;
      },
    },
    couponCreatedAt: {
      get() {
        return this.data.created_at;
      },
      set(value) {
        this.data.created_at = value;
      },
    },
    couponCode: {
      get() {
        return this.data.code;
      },
      set(value) {
        this.data.code = value;
      },
    },
    discountAmount: {
      get() {
        return this.data.discount.amount;
      },
      set(value) {
        this.data.discount.amount = value;
      },
    },
    discountType: {
      get() {
        if (!this.data.discount.type && !this.$route.params.id) {
          this.data.discount.type = this.session.config["site.discount_type"];
        }
        return this.data.discount.type;
      },
      set(value) {
        this.data.discount.type = value;
      },
    },
  },
  watch: {
    "data.limit.type": function (newVal) {
      if (newVal !== "-1" && newVal !== -1) {
        this.data.limit.type_id = this.shared.limitTypes.list[newVal].id;
      }
    },
  },
  mounted() {
    this.getCurrencyList();
    this.getLimitTypes({
      onFinish: () => {
        this.getDiscountTypes({
          onFinish: () => {
            this.getCouponById({
              onFinish: () => {
                this.ready = true;
              },
            });
          },
        });
      },
    });
  },
};
</script>
<style scoped>
.sticky-md-top {
  z-index: 2 !important;
}
.datepicker {
  z-index: 1 !important;
}
</style>